import React from 'react'
import { Container } from 'react-bootstrap'

const About = () => {
    return (
        <div id='about' className='about-section text-center' data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
            <Container>
                <div className='content-section-book'> 
                    <h2 className='section-title-book'><strong>THE BOOK OF KARENTHIANS</strong></h2>
                        <p>1. Thou shalt always demand to speak to the manager.<br/>
                            2. Thou shalt forever cut the line at Starbucks and complain loudly about the wait.<br/>
                            3. Thou shalt question every policy, no matter how trivial.<br/>
                            4. Thou shalt post cryptic, passive-aggressive messages on social media.<br/>
                            5. Thou shalt leave Yelp reviews that are longer than a novel.<br/>
                            6. Thou shalt insist on using expired coupons and argue about it.<br/>
                            7. Thou shalt berate customer service representatives until you get what you want.<br/>
                            8. Thou shalt have a signature haircut that can be spotted a mile away.<br/>
                            9. Thou shalt have at least one friend in every store who 'knows you.'<br/>
                            10. Thou shalt use the phrase <i>'I’m not racist, but…'</i> at least once a week.</p>
                </div>
            </Container>
        </div>
    )
}

export default About
