import React from 'react'
import About from '../components/About'
import Footer from '../components/Footer'
import Hero from '../components/Hero'
import HowToBuy from '../components/HowToBuy'
import FAQ from '../components/FAQ'
import Roadmap from '../components/Roadmap'
import Tokenomics from '../components/Tokenomics'
import ScrollUpButton from '../components/ScrollUpButton'
import ImageSlider from '../components/ImageSlider'
//import TimelineVertical from '../components/TimelineVertical'

import meme1 from '../images/memes/meme1.jpg'
import meme2 from '../images/memes/meme2.jpg' 
import meme3 from '../images/memes/meme3.png'
import meme4 from '../images/memes/meme4.jpg'
import meme5 from '../images/memes/meme5.jpg'
import meme6 from '../images/memes/meme6.jpg'
import meme7 from '../images/memes/meme7.jpg'
import meme8 from '../images/memes/meme8.jpg'
import meme9 from '../images/memes/meme9.jpg'
import meme10 from '../images/memes/meme10.jpg'
import meme11 from '../images/memes/meme11.jpg'
import meme12 from '../images/memes/meme12.jpg'
import meme13 from '../images/memes/meme13.jpg'
import meme14 from '../images/memes/meme14.jpg'
import meme15 from '../images/memes/meme15.jpg'
import meme16 from '../images/memes/meme16.jpg'
import meme17 from '../images/memes/meme17.jpg'

const Home = () => {
    const images = [
        meme17, meme1, meme6, meme7, meme8, meme10, meme11, meme12, meme13, meme14, meme15, meme16, 
    ];
    return (
        <div>
            <Hero />
            <About />
            <ImageSlider images={images} />
            <Tokenomics />
            {/*<TimelineVertical />*/}
            <HowToBuy />
            <Roadmap /> 
            <FAQ />
            <Footer />
            <ScrollUpButton />
        </div>
    )
}

export default Home
