import React from 'react'
import Navigation from './Navigation'
import { Container, Row, Col } from 'react-bootstrap'
import bubble from '../images/bubble.png'

const Hero = () => {
    return (
        <div className='hero-wrapper'>
            <div className='hero-section'>
                <Navigation />
                <div className='hero-inner'>
                    <Container>
                        <Row>
                            <Col md={6} id='first'>
                                <div className="hero-left" data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
                                    <div className="">
                                        <div className="mb-3">
                                            <h1 className="hero-title">$KAREN</h1>
                                        </div>
                                        <Row>
                                            <Col md={9}>
                                                <p className="hero-text">Welcome to the world of Doge’s Ex Wife - $KAREN, a meme coin inspired by the infamous Karen of the Dogelore universe. This whitepaper outlines the journey, purpose and future of $KAREN in the crypto space. Please note that this whitepaper is filled with nonsense jargon and pseudoscience, reflecting Karen’s own Facebook and social media posts.</p>
                                                <p className="hero-text">Doge’s Ex Wife - $KAREN aims to bring humor, community and a touch of absurdity to the crypto world. By embracing the Karen persona, we seek to create a unique and entertaining experience for our holders.</p>
                                                <div className='d-flex pt-3 btns-mobile-center'>
                                                    <a href='https://app.uniswap.org/swap?outputCurrency=0xFc9D434E5f81434922017F5A448350d0cCb0307c&chain=ethereumhttps://www.dextools.io/app/en/ether/pair-explorer/0x0c4dcfc9dac6273c233a9213473c9954e54f5b8a?t=1723821702895' target='_blank' rel="noreferrer" className="buy-now-btn hvr-back-pulse me-3">
                                                        Buy $KAREN
                                                    </a>
                                                    <a href='https://www.dextools.io/app/en/ether/pair-explorer/0x0c4dcfc9dac6273c233a9213473c9954e54f5b8a?t=1723821702895' target='_blank' rel="noreferrer" className="buy-now-btn hvr-back-pulse me-3">
                                                        View Chart
                                                    </a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} id='second'>
                                <div className="landing-hero-img" data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
                                    <img src={bubble} alt='logo' />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className='d-flex justify-content-center brands mb-3'>
                    {/* <img src={karen} className='' alt='karen' /> */}
                    {/* <img src={karen} className='' alt='karen' /> */}
                    {/* <img src={dextools} className='' alt='logo' /> */}
                    {/* <img src={cmc} className='' alt='logo' /> */}
                    {/* <img src={coingecko} className='' alt='logo' /> */}
                </div>
            </div>
        </div>
    )
}

export default Hero
