import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const Roadmap = () => {
    // Function will execute on click of button
    const onButtonClick = () => {
        // using Java Script method to get PDF file
        fetch('karen-whitepaper.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'karen-whitepaper.pdf';
                alink.click();
            })
        })
    }

    return (
        <div id='roadmap' className='about-section dark_bg' >
            <Container data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
                <div className='text-center'>
                    <h3 className='section-title text-white'>ROADMAP</h3>
                </div>
                <Row>
                <Col md={4} className='mb-4'>
                        <div className='section-inner section-card' data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
                            <div className='w-100'>
                                <h4 className='text-white roadmap-card-title'>Q3 2024</h4>
                                <h4 className='text-white'>Conception and Birth of Doge's Ex Wife - $KAREN</h4>
                                <ul type='disc'>
                                    <li className='text-white'>Inception: Doge's Ex Wife - $KAREN is conceptualized, drawing inspiration from the notorious Karen of the Dogelore universe. Our goal? To bring humor, community and a touch of absurdity to the crypto world.</li>
                                    <li className='text-white'>Website Launch: The official Doge's Ex Wife - $KAREN website goes live, complete with cringe-worthy Karen memes and unnecessary calls to imaginary managers.</li>
                                    <li className='text-white'>Whitepaper Release: A whitepaper filled with nonsense jargon and pseudoscience, reflecting Karen’s own Facebook and social media posts.</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} className='mb-4'>
                        <div className='section-inner section-card' data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
                            <div className='w-100'>
                                <h4 className='text-white'>Q4 2024</h4>
                                <h4 className='text-white'>Fair Launch and Community Building</h4>
                                <ul type='disc'>
                                    <li className='text-white'>Fair Launch: Doge's Ex Wife - $KAREN is distributed fairly to avoid accusations of favoritism (because Karen is the queen of calling out unfair practices). No pre-mining, no ICO, no presales—just a straight-up, fair launch.</li>
                                    <li className='text-white'>Community Kickoff: Start building the Doge's Ex Wife - $KAREN community with hilarious meme contests, Karen impersonation videos and debates on the most absurd topics.</li>
                                    <li className='text-white'>Listing on Exchanges: Initial listings on decentralized exchanges (DEXs) where the community can start trading Doge's Ex Wife - $KAREN.</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} className='mb-4'>
                        <div className='section-inner section-card' data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
                            <div className='w-100'>
                                <h4 className='text-white'>Q1 2025</h4>
                                <h4 className='text-white'>Development and Expansion</h4>
                                <ul type='disc'>
                                    <li className='text-white'>Partnerships: Secure partnerships with influencers who embody the spirit of Karen, amplifying our reach and impact.</li>
                                    <li className='text-white'>Tech Development: Begin development of the Doge's Ex Wife - $KAREN, featuring a built-in meme generator and an interactive KarenMeter.</li>
                                    <li className='text-white'>Charity Initiatives: Launch charitable initiatives supporting underpaid fast-food workers, turning Karen’s notorious complaints into positive action. Probably.</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='Row-2'>
                <Col md={4} className='mb-4'>
                        <div className='section-inner section-card' data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
                            <div className='w-100'>
                                <h4 className='text-white'>Q2 2025</h4>
                                <h4 className='text-white'>New Features and Integration</h4>
                                <ul type='disc'>
                                    <li className='text-white'>Enhanced Security: Implement advanced security measures to protect against any malicious Karen's trying to cause chaos.</li>
                                    <li className='text-white'>Merchant Adoption: Work towards getting Doge's Ex Wife - $KAREN accepted for promotional items in stores, especially those that have experienced a Karen meltdown.</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} className='mb-4'>
                        <div className='section-inner section-card' data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
                            <div className='w-100'>
                                <h4 className='text-white'>Q3 2025</h4>
                                <h4 className='text-white'>Global Domination (Karen Style):</h4>
                                <ul type='disc'>
                                    <li className='text-white'>Marketing Blitz: A global marketing campaign featuring viral Doge's Ex Wife - $KAREN videos and celebrity endorsements.</li>
                                    <li className='text-white'>Expansion to Major Exchanges: Listing Doge's Ex Wife - $KAREN on major exchanges, making it accessible to a wider audience.</li>
                                    <li className='text-white'>Community Events: Host worldwide community events where people can share their best Karen stories and win Doge's Ex Wife - $KAREN rewards.</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className='whitepaper w-100 pt-5'>
                    <div className='text-center'>
                        <h3 className='section-title text-white'>Whitepaper</h3>
                        <div className='d-flex justify-content-center'>
                            <div onClick={onButtonClick} className="buy-now-btn hvr-back-pulse" style={{ width: 225 }}>
                                Download Whitepaper
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Roadmap
