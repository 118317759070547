import React from 'react'
import logo from '../images/text-logo.png'
import { Navbar, Container, Nav } from 'react-bootstrap'
import { Link } from "react-router-dom";
import "../styles/Navigation.css"

const Navigation = () => {
    return (
        <div className='w-100'>
            <div className="ca">
                <div>CA: 0xFc9D434E5f81434922017F5A448350d0cCb0307c</div>
            </div>
            <Navbar expand="lg" variant="dark" className='pt-4'>
                <Container>
                    <Navbar.Brand as={Link} to="/"><img src={logo} alt="$karen logo" className='logo' /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ms-auto my-2 my-lg-0">
                            <Nav.Link href="#about" className="nav-link">THE BOOK OF KARENTHIANS</Nav.Link>
                            <Nav.Link href="#memes" className="nav-link">MEMES</Nav.Link>
                            <Nav.Link href="#tokenomics" className="nav-link">TOKENOMICS</Nav.Link>
                            <Nav.Link href="#how-to-buy" className="nav-link">HOW TO BUY</Nav.Link>
                            <Nav.Link href="#roadmap" className="nav-link">ROADMAP</Nav.Link>
                            <Nav.Link href="#faq" className="nav-link">FAQ</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default Navigation
