import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const HowToBuy = () => {
    return (
        <div id='how-to-buy' className='about-section' data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
            <Container>
                <div className='text-center'>
                    <h2 className='section-title'>HOW TO BUY</h2>
                </div>
                <Row className='how-to-buy'>
                <Col md={3} className='mb-3'>
                        <div className='section-inner section-card-2' data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
                            <div className='w-100'>
                                <h4 className='text-red-b'>Download MetaMask or Trust Wallet</h4>
                                <ul type='disc'>
                                    <li className='text-red'>First, you'll need a crypto wallet. MetaMask or Trust Wallet are great options.</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col md={3} className='mb-3'>
                        <div className='section-inner section-card-2' data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
                            <div className='w-100'>
                                <h4 className='text-red-b'>Purchase Ethereum ($ETH)</h4>
                                <ul type='disc'>
                                    <li className='text-red'>You can buy ETH directly on MetaMask or Trust Wallet using your debit/credit card or transfer ETH from another wallet.</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col md={3} className='mb-3'>
                        <div className='section-inner section-card-2' data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
                            <div className='w-100'>
                                <h4 className='text-red-b'>Connect to UniSwap</h4>
                                <ul type='disc'>
                                    <li className='text-red'>Go to UniSwap and connect your wallet. Make sure you are connected to the correct chain.</li>
                                </ul>
                            </div>
                        </div>
                    </Col> 
                    <Col md={3} className='mb-3'>
                        <div className='section-inner section-card-2' data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
                            <div className='w-100'>
                                <h4 className='text-red-b'>Swap ETH for $KAREN</h4>
                                <ul type='disc'>
                                    <li className='text-red'>Search for Doge's Ex-Wife - $KAREN, enter the amount of ETH you want to swap, and execute the trade.</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default HowToBuy
