import React, { useState } from "react";
import { Container } from "react-bootstrap";
import "../styles/ImageSlider.css";
import rotate from "../images/karen-rotate.gif";

const ImageSlider = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrentIndex(
            (prevIndex) => (prevIndex - 1 + images.length) % images.length
        );
    };

    return (
        <div className="about-section" id="memes">
            <Container
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-once="true"
            >
                <div className="slider">
                    <div className="text-center">
                        <div className="inline-container">
                            <img
                                src={rotate}
                                alt="rotate"
                                className="rotating"
                            />
                            <h3 className="section-title text-white">MEMES</h3>
                            <img
                                src={rotate}
                                alt="rotate"
                                className="rotating"
                            />
                        </div>
                    </div>
                    <div className="slider-image-container">
                        <img
                            src={images[currentIndex]}
                            alt="slide"
                            className="slider-image"
                        />
                    </div>
                    <div className="slider-buttons">
                        <button onClick={prevSlide} className="slider-button">
                            Previous
                        </button>
                        <button onClick={nextSlide} className="slider-button">
                            Next
                        </button>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default ImageSlider;
