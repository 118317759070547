import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import square1 from '../images/icons/square1.svg'
import square2 from '../images/icons/square2.svg'
import logo from '../images/karen-logo-large.png'

const Tokenomics = () => {
    return (
        <Container data-aos="fade-up" data-aos-duration="1000" data-aos-once="true" id="tokenomics" className='about-section-tokenomics'>
            <div className='text-center'>
                <h2 className='section-title-tokenomics'>TOKENOMICS</h2>
            </div>
            <div className='hero-gradient-bg'>
                <div className='hero-gradient-bg1'>
                    <img src={square1} alt='square' />
                </div>
                <Row>
                    <Col md={8}>
                        <div className='d-flex justify-content-center align-items-center h-100'>
                            <Row> 
                                <Col md={6}>
                                    <div className='hero-gradient-1'>
                                        <h4>0%</h4>
                                        <p>Tax</p>
                                        <p>Contract renounced (<a href='https://etherscan.io/tx/0x07de5093c99b1d415fada44c5c8ecdb364d998e6a45b47295f2300ce1e35f5ff' style={{color: 'white'}}>tx 👀</a>) <br/>
                                        0/0 tax, LP burned</p>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='hero-gradient-1'>
                                        <h4>69,000,000,000</h4>
                                        <p>Total Supply</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='text-center'>
                            <img src={logo} alt='logo' width='200px' />
                        </div>
                    </Col>
                </Row>
                <div className='hero-gradient-bg2'>
                    <img src={square2} alt='square' />
                </div>
            </div>
        </Container>
    )
}

export default Tokenomics
