import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import faq1 from "../images/faq-1.jpg";
import faq2 from '../images/faq2.png'

const Metaverse = () => {
    return (
        <div id="faq" className="about-section">
            <Container
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-once="true"
            >
                <div className="text-center">
                    <h3 className="section-title">
                    FREQUENTLY ASKED QUESTIONS
                    </h3>
                </div>
                <Row>
                    <Col md={6} className="mb-4">
                        <div className="section-inner" data-aos="fade-up" data-aos-duration="1000" data-aos-once="true" >
                            <div>
                                <p>
                                    {" "}
                                    <strong>
                                        {" "}
                                        What is Doge's Ex-Wife - $KAREN and how does it work?{" "}
                                    </strong>{" "}
                                </p>
                                <p>
                                    Doge's Ex-Wife - $KAREN is a meme cryptocurrency that embodies the spirit of Karen. It's designed to be as entertaining and absurd as the legendary Karen herself, bringing humor and community engagement to the crypto world. You buy, sell and trade $KAREN just like any other crypto, but with the added fun of being part of the Karen universe.
                                </p>

                                <p>
                                    {" "}
                                    <strong>
                                        {" "}
                                        Where can I buy Doge's Ex-Wife - $KAREN?{" "}
                                    </strong>{" "}
                                </p>
                                <p>
                                    You can buy $KAREN on UniSwap. Because, of course, Karen would insist on a decentralized platform where she can complain about liquidity issues directly.
                                </p>

                                <div className="token-image" data-aos="fade-up" data-aos-duration="1000" data-aos-once="true" >
                                    <img src={faq1} alt="faq1" />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="mb-4">
                        <div className="token-image" data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
                            <img src={faq2} alt="faq2" />
                            </div>
                            <p>
                                {" "}
                                <strong>
                                    How do I become a Karen today?{" "}
                                </strong>{" "}
                            </p>
                            <p>
                                It's simple, hun! Just head over to UniSwap, buy some $KAREN and join our community. Participate in our events, share your best Karen stories and spread the Karen spirit far and wide. Welcome to the club!
                            </p>

                            <p>
                                {" "}
                                <strong>
                                    {" "}
                                    What are the potential risks of investing in Doge's Ex-Wife - $KAREN?{" "}
                                </strong>{" "}
                            </p>
                            <p>
                                Investing in $KAREN is like playing Russian roulette with Karen's mood swings. It's a meme coin with no intrinsic value, so treat it as such. Remember, if you can't handle the heat, don't ask to speak to the manager!{" "}
                            </p>
                            <p>
                                    {" "}
                                    <strong>
                                        {" "}
                                        What makes Doge's Ex-Wife - $KAREN unique compared to other meme coins?{" "}
                                    </strong>
                                </p>
                                <p>
                                    $KAREN stands out because it's not just another meme coin; it's a lifestyle. With hilarious meme contests, Karen impersonation videos and exclusive partnerships with influencers who embrace the Karen spirit, $KAREN offers a community experience that's as unforgettable as Karen's rants.
                                </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Metaverse;
